@use "@angular/material" as mat;

@mixin theme($theme) {
  ath-activity-filter {
    --divider-color: #{mat.get-theme-color($theme, surface-dim)};

    .fab-btn {
      transition: border-radius 0.15s ease-in-out;
    }

    .fab-btn[aria-expanded="true"] {
      border-top-left-radius: var(--mat-menu-container-shape);
      border-top-right-radius: var(--mat-menu-container-shape);
      border-bottom-left-radius: 35px;
      border-bottom-right-radius: 35px;
    }

    mat-checkbox {
      .mdc-label {
        margin-left: calc(var(--spacing-sm) * -1);
      }
    }

    .header {
      z-index: 2;
      box-shadow: 0px 4px 3px -3px #{mat.get-theme-color($theme, surface-dim)};
    }
  }

  .mat-mdc-menu-panel {
    min-width: 133px;
  }
}
