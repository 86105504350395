@use "@angular/material" as mat;

@mixin theme($theme) {
  .nav {
    --navbar-border-color: #{mat.get-theme-color($theme, on-tertiary)};
    background-color: mat.get-theme-color($theme, tertiary, 20);

    .nav-logout {
      --mdc-icon-button-state-layer-size: 24px;
    }

    * {
      color: mat.get-theme-color($theme, tertiary, 100);
    }
  }
}
