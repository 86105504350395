@use "./theme/_theme.scss" as theme;
@use "./_spacings.css";
@forward "./layouts.scss";

html {
  height: 100dvh;
}

body {
  height: 100%;
  line-height: 1.5;

  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

input,
textarea,
select {
  display: block;
  font: inherit;
}

input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
