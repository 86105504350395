@use "@angular/material" as mat;
@use "src/styles/theme/helpers/color-helpers.scss";

// TODO: https://touchtechnology.atlassian.net/browse/ATH-875
// Commented lines are left in for reference
@mixin theme($theme) {
  ath-activity-grid {
    // TODO: https://touchtechnology.atlassian.net/browse/ATH-625 Table variables should be made global
    // --ath-table-row-selected-color: #f0f0f0;
    // --ath-table-row-hover-selected-color: darken(#f0f0f0, 10%);

    // --ath-table-header-bg: #{mat.get-theme-color($theme, secondary-fixed)};
    --ath-table-header-bg: #{mat.get-theme-color($theme, surface-container-low)};
    // --ath-table-header-bg: #{color-helpers.theme-rgba($theme, secondary-fixed, 0.4)};
    --ath-table-row-hover-color: #{color-helpers.theme-rgba($theme, secondary-fixed, 0.4)};
    // --ath-table-row-alt-color: #{color-helpers.theme-rgba($theme, secondary-fixed, 0.1)};
    // tertiary-container
    @include mat.table-overrides(
      (
        background-color: mat.get-theme-color($theme, surface-container-low),
        // header-headline-color: mat.get-theme-color($theme, on-tertiary),
      )
    );

    @include mat.toolbar-overrides(
      (
        container-background-color: var(--ath-table-header-bg),
        // container-text-color: mat.get-theme-color($theme, on-tertiary),
      )
    );

    @include mat.paginator-overrides(
      (
        container-background-color: transparent,
        // container-text-color: mat.get-theme-color($theme, on-tertiary),
      )
    );
  }
}
