@use "@angular/material" as mat;

@mixin theme($theme) {
  ath-audit-log {
    --bg-color: #{mat.get-theme-color($theme, surface-container-low)};
    --header-footer-color: #{mat.get-theme-color($theme, secondary-container)};
    --on-header-footer-color: #{mat.get-theme-color($theme, on-secondary-container)};

    --mat-table-row-item-container-height: 65px;

    ath-audit-search {
      mat-form-field {
        width: 350px;
      }
    }
  }
}
