@use "@angular/material" as mat;

@mixin theme($theme) {
  ath-filter-base {
    .mat-expansion-panel-header-title.error {
      gap: 8px;
      color: #{mat.get-theme-color($theme, error)};
    }

    .error-info-column {
      font-size: 0.875em;
      color: #666;
      margin-top: var(--spacing-md);
    }
  }

  ath-filter-base:focus {
    .mat-expansion-panel {
      box-shadow:
        0px 3px 1px -2px #{mat.get-theme-color($theme, tertiary)},
        0px 2px 2px 0px #{mat.get-theme-color($theme, tertiary)},
        0px 1px 5px 0px #{mat.get-theme-color($theme, tertiary)};
    }
  }
}
