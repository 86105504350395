@use "@angular/material" as mat;

@mixin theme($theme) {
  ath-time-picker-details {
    --time-picker-input-background-color: var(
      --mat-datepicker-calendar-date-hover-state-background-color
    );
    --time-picker-input-text-color: var(--mat-datepicker-calendar-container-text-color);
    --time-picker-input-focus-color: var(--mat-datepicker-calendar-date-selected-state-text-color);
    --time-picker-input-radius: 8px;
    --time-picker-input-font-size: 36px;

    --time-picker-accent-color: var(--mat-datepicker-calendar-date-selected-state-background-color);

    --time-picker-suffix-color: mat.get-theme-color($theme, on-surface);
    --time-picker-suffix-font-size: var(--mat-datepicker-calendar-period-button-text-size);

    border-radius: var(--mat-datepicker-calendar-container-shape);
    background-color: var(--mat-datepicker-calendar-container-background-color);
  }
}
