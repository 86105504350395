@use "@angular/material" as mat;

@mixin theme($theme) {
  mat-dialog-container {
    @include mat.dialog-overrides(
      (
        with-actions-content-padding: var(--spacing-lg),
        container-color: mat.get-theme-color($theme, surface),
        supporting-text-color: mat.get-theme-color($theme, on-surface),
      )
    );
  }

  & {
    --drag-preview-bg: #{mat.get-theme-color($theme, surface)};
  }
}
