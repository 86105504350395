@use "@angular/material" as mat;

@mixin theme($theme) {
  .secondary-button {
    @include mat.button-color($theme, $color-variant: secondary);
  }

  // .mdc-button.tertiary-button {
  // @include mat.button-color($theme, $color-variant: tertiary); // TODO use when theme colors are defined
  // }

  .mat-mdc-fab {
    --mdc-fab-container-color: #{mat.get-theme-color($theme, tertiary)};
    --mat-fab-foreground-color: #{mat.get-theme-color($theme, on-tertiary)};
  }
}
