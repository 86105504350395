@use "@angular/material" as mat;
@use "sass:color";

@mixin theme($theme) {
  ath-audio-player {
    $base-color: mat.get-theme-color($theme, tertiary, 20);
    $base-container-color: mat.get-theme-color($theme, secondary, 40);
    $font-color: mat.get-theme-color($theme, tertiary, 100);

    background: linear-gradient(to right, $base-color, scale-color($base-color, $lightness: 20%));
    color: $font-color;
    --mat-icon-color: #{$font-color};

    #play-pause-btn {
      background-color: $base-container-color;
      border-radius: 50%;
      --mdc-icon-button-state-layer-size: 64px; // Button size
      padding: 0;

      mat-icon {
        --mdc-icon-button-icon-size: 48px;
        width: var(--mdc-icon-button-icon-size);
        height: var(--mdc-icon-button-icon-size);
      }
    }

    .playback-rate {
      --mat-select-enabled-trigger-text-color: #{$font-color};
      --mat-select-enabled-arrow-color: #{$font-color};
    }

    .playback-timer {
      background-color: $base-container-color;
    }

    .playback-rate {
      mat-select {
        .mat-mdc-select-value {
          text-align: center;
        }
      }
    }

    .info {
      color: mat.get-theme-color($theme, tertiary, 70);
    }
  }
}
