@use "@angular/material" as mat;

@mixin theme($theme) {
  mat-drawer-container {
    --mat-sidenav-container-width: fit-content;

    @include mat.sidenav-overrides(
      (
        container-background-color: mat.get-theme-color($theme, surface-container-low),
        content-background-color: transparent,
      )
    );
  }
}
