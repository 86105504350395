@use "@angular/material" as mat;

@mixin theme($theme) {
  mat-chip-set {
    $border-radius: 8px;

    & button {
      --mdc-filled-button-container-height: auto;
      --mdc-filled-button-container-shape: #{$border-radius};
      margin: var(--spacing-xs) 0 var(--spacing-xs) var(--spacing-sm);

      > mat-icon {
        height: 100% !important;
        --mat-icon-color: #{mat.get-theme-color($theme, surface)};
      }
    }

    @include mat.chips-overrides(
      (
        with-icon-icon-size: 24px,
        elevated-container-color: mat.get-theme-color($theme, surface),
        container-shape-radius: $border-radius,
        outline-color: mat.get-theme-color($theme, tertiary),
        container-height: 36px,
      )
    );
  }
}
