@use "@angular/material" as mat;

@mixin theme($theme) {
  ath-loading-ripple {
    $base-diameter: 15dvh;
    $ring-width: 35px;
    $ring-color: #{mat.get-theme-color($theme, primary, 80)};

    .ring {
      width: $base-diameter;
      height: $base-diameter;
      border: $ring-width solid $ring-color;
    }
  }
}
