@use "@angular/material" as mat;

@mixin theme($theme) {
  mat-tab-group {
    overflow: auto;

    > div.mat-mdc-tab-body-wrapper {
      flex: auto;
    }
  }
}
