@use "@angular/material" as mat;
@use "sass:color";

@mixin theme($theme) {
  ath-text-viewer {
    --ath-incoming-chat-background: #{scale-color(
        mat.get-theme-color($theme, tertiary-container),
        $lightness: 0%
      )};
    --ath-on-incoming-chat-background: #{mat.get-theme-color($theme, on-tertiary-container)};

    --ath-outgoing-chat-background: #{scale-color(
        mat.get-theme-color($theme, primary),
        $lightness: 1%
      )};
    --ath-on-outgoing-chat-background: #{mat.get-theme-color($theme, on-primary)};

    .error-message {
      display: flex;
      align-items: center;
      border-radius: 4px;
      border: 1px solid black;
      padding: var(--spacing-sm);
      width: fit-content;
    }
  }
}
